import React, {useContext, useEffect} from "react";
import {useHistory} from "react-router-dom";
import firebase from "../../../firebase";
import "firebase/auth";
import {AuthContext} from "../../../AuthProvider";
import {
    Container,
    Row,
    Col,
    CardGroup,
    Card,
    CardBody,
    Button,
} from 'reactstrap';
import {Form, Formik, Field} from 'formik';

const Login = () => {
    const authContext = useContext(AuthContext);
    const {loadingAuthState} = useContext(AuthContext);
    const history = useHistory();

    useEffect(() => {
        if (!firebase.auth().currentUser) {
            return;
        }

        history.push("/dashboard");
    }, []);

    const validate = (values: any) => {
        let errors: any = {};

        if (!values.email) {
            errors.email = "Please enter your registered email address";
        }

        if (!values.password) {
            errors.password = "Please enter your password";
        }

        return errors;
    }

    const handleSubmit = (values) => {
        firebase
            .auth()
            .signInWithEmailAndPassword(values.email, values.password)
            .then(res => {
                authContext.setUser(res);
                history.push("/dashboard");
            })
            .catch(error => {
                alert(error.message);
            });
    }

    if (loadingAuthState) {
        return (
            <div>
                <h1>Loading...</h1>
            </div>
        );
    }

    return (
        <div className="app flex-row align-items-center">
            <Container>
                <Row className="justify-content-center">
                    <Col md="8">
                        <CardGroup>
                            <Card className="p-4">
                                <Formik
                                    validate={validate}
                                    initialValues={{
                                        email: "",
                                        password: "",
                                    }}
                                    onSubmit={handleSubmit}
                                >
                                    {({errors, touched, isValidating}) => (
                                        <Form className="av-tooltip tooltip-label-right login100-form validate-form">
                                            <CardBody>
                                                <h1>Login</h1>
                                                <p className="text-muted">Sign In to your account</p>
                                                <div className="form-group">
                                                    <Field
                                                        className="form-control"
                                                        name="email"
                                                        placeholder="Email"
                                                    />
                                                    {errors.email && touched.email && (
                                                        <div className="invalid-feedback-msg">
                                                            {errors.email}
                                                        </div>
                                                    )}
                                                </div>
                                                <div className="form-group mb-4">
                                                    <Field
                                                        className="form-control"
                                                        name="password"
                                                        type="password"
                                                        placeholder="Password"
                                                    />
                                                    {errors.password && touched.password && (
                                                        <div className="invalid-feedback-msg">
                                                            {errors.password}
                                                        </div>
                                                    )}
                                                </div>
                                                <Row>
                                                    <Col xs="6">
                                                        <Button color="primary" className="px-4">
                                                            Login
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Form>
                                    )}
                                </Formik>
                            </Card>
                        </CardGroup>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default Login;
