import * as React from 'react';
import firebase from "../../firebase";
import {
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Dropdown,
} from 'reactstrap';

class HeaderDropdown extends React.Component<any, any> {
    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.state = {
            dropdownOpen: false,
        };
    }

    toggle() {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen,
        });
    }

    logout = (event: any) => {
        event.preventDefault();
        firebase
            .auth()
            .signOut();
    }

    dropAccnt() {
        return (
            <Dropdown nav isOpen={this.state.dropdownOpen} toggle={this.toggle}>
                <DropdownToggle nav>
                    <img
                        src={'img/avatars/6.jpg'}
                        className="img-avatar"
                        alt="admin@bootstrapmaster.com"
                    />
                </DropdownToggle>
                <DropdownMenu right>
                    <DropdownItem header tag="div" className="text-center">
                        <strong>Account</strong>
                    </DropdownItem>
                    <DropdownItem onClick={this.logout}>
                        <i className="fa fa-lock"/> Logout
                    </DropdownItem>
                </DropdownMenu>
            </Dropdown>
        );
    }

    render() {
        // const { ...attributes } = this.props;
        return this.dropAccnt();
    }
}

export default HeaderDropdown;
