import {
    formatApiException,
    formatApiResponse,
} from '../common/helper';
import {
    UploadClientInput
} from '../common/types';

export default class ApiService {
    static async uploadClient(
        payload: UploadClientInput,
    ): Promise<{
        data: any[] | null;
        errors: string[] | null;
    }> {
        try {
            if (payload && payload.client) {
                let form = new FormData();
                form.append("file", payload.client);
                form.append("clientName", payload.clientName);

                const requestOptions = {
                    method: "POST",
                    body: form,
                };

                const {data, errors} = await fetch(
                    process.env.REACT_APP_API_URL,
                    requestOptions
                ).then(response => response.json())
                    .then((response) => response)
                    .catch((error) => error);

                return formatApiResponse(
                    data,
                    errors ? [...errors] : null,
                );
            } else {
                return {
                    data: null,
                    errors: null
                };
            }
        } catch (e) {
            return formatApiException(e);
        }
    }
}
