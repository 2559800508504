import React, {useEffect, useState} from 'react';
import UploadClientForm from './UploadClientForm';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';

import {RootState, UploadClientInput} from '../../common/types';
import {
    uploadClient,
    resetUploadClientSuccess
} from './client.slice';
import {Card, CardBody, Col, Row} from 'reactstrap';

const Client: React.FC = (): JSX.Element => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [uploadErr, setUploadErr] = useState<any[] | null>(null);

    const {
        uploadClientState: {
            loading,
            success,
            errors
        },
    } = useSelector((state: RootState) => state.client);

    function onSubmit(data: UploadClientInput) {
        dispatch(uploadClient(data));
    }

    useEffect(() => {
        if (success) {
            alert('Records uploaded successfully');
            dispatch(resetUploadClientSuccess());
            window.location.reload();
        }

        if (errors && errors.length > 0) {
            setUploadErr(errors);
            dispatch(resetUploadClientSuccess());
            setTimeout(() => {
                window.location.reload();
            }, 10000);
        }
    }, [success, errors, dispatch, history]);

    const uploadClientInit = {
        clientName: ''
    };

    return (
        <Row>
            <Col xs="12" sm="12" lg="12">
                <Card>
                    <CardBody className="pb-0">
                        <UploadClientForm
                            onSubmit={onSubmit}
                            loading={loading}
                            initialValues={uploadClientInit}
                        />
                        {
                            uploadErr && uploadErr.length > 0 ?
                                uploadErr.map((item) => {
                                    return (
                                        <p style={{color: 'red'}}>{item}</p>
                                    )
                                }) : null
                        }
                    </CardBody>
                </Card>
            </Col>
        </Row>
    );
};

export default Client;
