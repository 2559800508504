/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeEvery } from 'redux-saga/effects';

import {
    UploadClientInput
} from '../../common/types';
import ApiService from '../../services/api';
import {
    uploadClient,
    uploadClientError,
    uploadClientSuccess,
} from './client.slice';

export function* uploadClientAsync(
    action: PayloadAction<UploadClientInput>,
) {
    const {data, errors} = yield call(
        ApiService.uploadClient,
        action.payload,
    );

    if (errors) {
        yield put(uploadClientError(errors));
    } else {
        yield put(uploadClientSuccess(data));
    }
}

export function* watchClientAsync() {
    yield takeEvery(uploadClient.toString(), uploadClientAsync);
}
