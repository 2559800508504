import {
    ApolloClient,
    ApolloLink,
    InMemoryCache,
    Observable,
} from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';

const requestLink = new ApolloLink(
    (operation, forward) =>
        new Observable((observer) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            let handle: any;
            Promise.resolve(operation)
                .then(() => {
                    handle = forward(operation).subscribe({
                        next: observer.next.bind(observer),
                        error: observer.error.bind(observer),
                        complete: observer.complete.bind(observer),
                    });
                })
                .catch(observer.error.bind(observer));

            return () => {
                if (handle) handle.unsubscribe();
            };
        }),
);

const client = new ApolloClient({
    link: ApolloLink.from([
        requestLink,
        createUploadLink({
            uri: process.env.REACT_APP_API_URL,
        }),
    ]),
    cache: new InMemoryCache({
        addTypename: false,
    }),
    defaultOptions: {
        mutate: {
            errorPolicy: 'all',
            fetchPolicy: 'no-cache',
        },
        query: {
            errorPolicy: 'ignore',
            fetchPolicy: 'no-cache',
        },
        watchQuery: {
            errorPolicy: 'ignore',
            fetchPolicy: 'no-cache',
        },
    },
});

export default client;
