import * as React from 'react';
import * as ReactDOM from 'react-dom';

import * as serviceWorker from './registerServiceWorker';
// Styles
// Import Main styles for this application
import './scss/style.scss';
import './scss/_custom.scss';
// Temp fix for reactstrap
import './scss/core/_dropdown-menu-right.scss';
import {AuthProvider} from './AuthProvider';
import App from "./App";


ReactDOM.render(<AuthProvider><App/></AuthProvider>, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
