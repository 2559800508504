import {GraphQLError} from 'graphql';

// eslint-disable-next-line import/prefer-default-export
export function extractErrors(err: GraphQLError[]): string[] {
    const [{extensions, message}] = err;
    let errors: any = [];

    if (extensions) {
        if (extensions.exception.response) {
            const {message: resMessage} = extensions.exception.response;

            if (Array.isArray(resMessage)) {
                errors = resMessage;
            } else {
                errors = [resMessage];
            }
        } else {
            errors = [message];
        }
    }

    return errors;
}

export function formatApiResponse(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data: Record<string, any> | null,
    errors: any | null,
): {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data: any;
    errors: string[] | null;
} {
    return {
        data: data ? data.result : null,
        errors: data ? data.error : null,
    };
}

export function formatApiException(exception: {
    message: string;
}): {
    data: null;
    errors: string[];
} {
    return {data: null, errors: [exception.message]};
}