let firebaseConfig = {
    apiKey: "AIzaSyBVDNnlzYU8bgDznFAibczjFa4iRMMGY8Y",
    authDomain: "beekmarks-test-c9a89.firebaseapp.com",
    databaseURL: "https://beekmarks-test-c9a89.firebaseio.com",
    projectId: "beekmarks-test-c9a89",
    storageBucket: "beekmarks-test-c9a89.appspot.com",
    messagingSenderId: "1063679704204",
    appId: "1:1063679704204:web:7484b6048e631c76390fa9"
};

export default firebaseConfig;