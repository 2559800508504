import * as React from 'react';
import {
    Nav,
    NavbarBrand,
    NavbarToggler,
} from 'reactstrap';
import HeaderDropdown from './HeaderDropdown';

class Header extends React.Component {
    constructor(props) {
        super(props);
    }

    sidebarToggle(e) {
        e.preventDefault();
        document.body.classList.toggle('sidebar-hidden');
    }

    sidebarMinimize(e) {
        e.preventDefault();
        document.body.classList.toggle('sidebar-minimized');
    }

    mobileSidebarToggle(e) {
        e.preventDefault();
        document.body.classList.toggle('sidebar-mobile-show');
    }

    render() {
        return (
            <header className="app-header navbar">
                <NavbarToggler className="d-lg-none" onClick={this.mobileSidebarToggle}>
                    <span className="navbar-toggler-icon"/>
                </NavbarToggler>
                <NavbarBrand href="#"/>
                <NavbarToggler className="d-md-down-none" onClick={this.sidebarToggle}>
                    <span className="navbar-toggler-icon"/>
                </NavbarToggler>
                <Nav className="ml-auto" navbar>
                    <HeaderDropdown/>
                </Nav>
            </header>
        );
    }
}

export default Header;
