import React, { useState } from 'react';

type FileUploadProps = {
    id: string;
    label: string;
    onFileSelect: (image: File | null) => void;
    onRemoveUrl?: undefined | (() => void);
    url?: string | null;
    required?: boolean;
    maxFileSize?: number | null;
    disabled?: boolean;
};

const FileUpload: React.FC<FileUploadProps> = ({
                                                   id,
                                                   label,
                                                   onFileSelect,
                                                   onRemoveUrl,
                                                   url,
                                                   required,
                                                   maxFileSize,
                                                   disabled,
                                               }: FileUploadProps): JSX.Element => {
    const [maxSizeErr, setMaxSizeErr] = useState<string | null>(null);

    function onFileChange({
                              target: {files},
                          }: React.ChangeEvent<HTMLInputElement>) {
        if (files && files.length > 0) {
            setMaxSizeErr(null);

            const file = files[0];
            const sizeInKB = Math.round(file.size / 1024);

            if (maxFileSize && sizeInKB > maxFileSize) {
                setMaxSizeErr(
                    'Max allowed image size is' + maxFileSize + 'KB',
                );
                return;
            }

            const reader = new FileReader();

            reader.onload = function onLoad() {
                onFileSelect(file);
            };

            reader.readAsDataURL(file);
        }
    }

    return (
        <>
            <div className="upload-image-field">
                <div className="form-group">
                    <label
                        className={maxSizeErr ? 'text-secondary small' : ''}
                        style={maxSizeErr ? {color: 'red'} : {}}
                    >
                        {label}
                        {required ? (
                            <sup className="require-field">
                                *
                            </sup>
                        ) : null}
                    </label>
                    {maxSizeErr ? <p>{maxSizeErr}</p> : null}
                    <div className="input-group">
                        <div className="custom-file">
                            <input
                                type="file"
                                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                className="custom-file-input"
                                id={id}
                                aria-describedby="inputGroupFileAddon04"
                                onChange={onFileChange}
                                disabled={disabled}
                            />
                            <label htmlFor={id} className="custom-file-label"/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 text-right">
                            {maxFileSize ? (
                                <small className="common-text-color short-desc">
                                    Max. Size {maxFileSize}KB
                                </small>
                            ) : null}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

FileUpload.defaultProps = {
    url: null,
    maxFileSize: null,
    required: false,
    disabled: false,
    onRemoveUrl: undefined,
};

export default FileUpload;