import * as React from 'react';

class Footer extends React.Component {
    render() {
        return (
            <footer className="app-footer">
                <span>&copy; Copyright 2020. All Rights Reserved.</span>
                <span className="ml-auto">Powered by <a href="https://www.beekmarks.com">Beekmarks</a></span>
            </footer>
        );
    }
}

export default Footer;
