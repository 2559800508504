/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { all, call } from 'redux-saga/effects';

import { watchClientAsync } from './views/Client/client.saga';

// single entry point to start all Sagas at once
export default function* rootSaga() {
    yield all([
        call(watchClientAsync)
    ]);
}
