/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
    ClientState,
    UploadClientInput
} from '../../common/types';

const initialState: ClientState = {
    loading: false,
    success: null,
    errors: [],
    uploadClientState: {
        loading: false,
        success: null,
        errors: [],
    }
};

const clientSlice = createSlice({
    name: 'client',
    initialState,
    reducers: {
        uploadClient(state, action: PayloadAction<UploadClientInput>) {
            state.uploadClientState.loading = true;
            state.uploadClientState.errors = [];
        },
        uploadClientSuccess(state, action: PayloadAction<boolean>) {
            state.uploadClientState.loading = false;
            state.uploadClientState.success = action.payload;
        },
        uploadClientError(state, action: PayloadAction<string[]>) {
            state.uploadClientState.loading = false;
            state.uploadClientState.errors = action.payload;
        },
        resetUploadClientSuccess(state) {
            state.uploadClientState.success = null;
            state.uploadClientState.errors = [];
        },
    },
});

export const {
    uploadClient,
    uploadClientSuccess,
    uploadClientError,
    resetUploadClientSuccess
} = clientSlice.actions;

export const {
    name: clientSliceName,
    reducer: clientSliceReducer,
} = clientSlice;
