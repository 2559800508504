import { combineReducers } from '@reduxjs/toolkit';
import { connectRouter } from 'connected-react-router';
import history from './history';
import {
    clientSliceName,
    clientSliceReducer,
} from './views/Client/client.slice';

const client = {
    [clientSliceName]: clientSliceReducer,
};

const router = { router: connectRouter(history) };

export const rootReducer = combineReducers({
    ...router,
    ...client
});

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function createReducer(injectedReducers = {}) {
    return combineReducers({
        ...injectedReducers,
        ...client
    });
}

export type RootState = ReturnType<typeof rootReducer>;
