import {Field, Form, Formik} from 'formik';
import React, { useState } from 'react';

import FileUpload from '../../components/FileUpload';
import { UploadClientInput } from '../../common/types';

type UploadClientFormProps = {
    onSubmit: (data: UploadClientInput) => void;
    loading: boolean;
    initialValues: UploadClientInput;
};

const UploadClientForm: React.FC<UploadClientFormProps> = ({
                                                                     onSubmit,
                                                                     loading,
                                                                     initialValues,
                                                                 }: UploadClientFormProps): JSX.Element => {
    const [selectedImage, setSelectedImage] = useState<File | null>(null);
    const validate = (values: any) => {
        let errors: any = {};

        if (!values.clientName) {
            errors.clientName = "Please enter user name";
        } else {
            let pattern = /^[a-zA-Z0-9 ]*$/;
            if (!pattern.test(values.clientName)) {
                errors.clientName = "Please enter valid user name";
            }
        }

        return errors;
    }

    return (
        <Formik
            initialValues={initialValues}
            validate={validate}
            onSubmit={(values, {setSubmitting}) => {
                const input = {...values};

                if (selectedImage) {
                    input.client = selectedImage;
                }

                onSubmit(input);
                setSubmitting(false);
            }}
        >
            {({errors, touched, isValidating}) => {
                return (
                    <Form>
                        <div className="form">
                            <div className="row">
                                <div className="col-md-4 col-12">
                                    <div className="form-group">
                                        <label>UserName*</label>
                                        <Field
                                            className="form-control"
                                            name="clientName"
                                            placeholder="User Name"
                                        />
                                        {errors.clientName && touched.clientName && (
                                            <div className="invalid-feedback-msg">
                                                {errors.clientName}
                                            </div>
                                        )}
                                    </div>

                                </div>
                                <div className="col-md-6 col-12">
                                    <FileUpload
                                        id="upload-profile-photo"
                                        label="Upload Client"
                                        onFileSelect={(file) => {
                                            setSelectedImage(file);
                                        }}
                                        maxFileSize={500}
                                        disabled={loading}
                                        required={true}
                                    />
                                </div>
                                <div className="col-md-2 col-12 mt-4">
                                    <button
                                        type="submit"
                                        className="btn btn-success"
                                        disabled={loading}
                                    >
                                        Upload
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Form>
                );
            }}
        </Formik>
    );
};

export default UploadClientForm;
