export default {
    items: [
        {
            name: 'Client',
            url: '/app/client',
            icon: 'icon-cloud-upload'
        },
        {
            name: 'Swagger Docs',
            url: 'https://api.beekmarks.com/swagger-ui/',
            icon: 'icon-docs'
        }
    ],
};
