import {ApolloProvider} from '@apollo/client';
import {Provider} from 'react-redux';
import {HashRouter, Route, Switch, Redirect} from 'react-router-dom';
import configureStore from './store';
import client from './apolo.client';
import * as React from 'react';
import PrivateRoute from "./PrivateRoute";
import './App.scss';

// Containers
import Full from './containers/Full';

// Views
import Login from './views/Pages/Login/';

const initialState = {};
const store = configureStore(initialState);

function App() {
    return (
        <Provider store={store}>
            <ApolloProvider client={client}>
                <HashRouter>
                    <Switch>
                        <PrivateRoute path="/app" component={Full}/>
                        <Route exact path="/login" component={Login}/>
                        <Redirect to="/app" from="/"/>
                    </Switch>
                </HashRouter>
            </ApolloProvider>
        </Provider>
    );
}

export default App;
