import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { routerMiddleware } from 'connected-react-router';
import { createInjectorsEnhancer } from 'redux-injectors';
import createSagaMiddleware from 'redux-saga';

import history from './history';
import createReducer from './rootReducer';
import rootSaga from './rootSaga';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function configureAppStore(initialState = {}) {
    const reduxSagaMonitorOptions = {};
    const sagaMiddleware = createSagaMiddleware(reduxSagaMonitorOptions);

    const {run: runSaga} = sagaMiddleware;

    // sagaMiddleware: Makes redux-sagas work
    const middlewares = [sagaMiddleware, routerMiddleware(history)];

    const enhancers = [
        createInjectorsEnhancer({
            createReducer,
            runSaga,
        }),
    ];

    const store = configureStore({
        reducer: createReducer(),
        middleware: [...getDefaultMiddleware({
                                                 serializableCheck: false
                                             }), ...middlewares],
        preloadedState: initialState,
        devTools: true,
        enhancers,
    });

    sagaMiddleware.run(rootSaga);
    return store;
}
